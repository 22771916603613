<template>
    <b-overlay :show="formShow" rounded="sm" no-fade class=" mt-2">
        <b-card>
            <b-row>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">User</th>
                        </tr>
                    </thead>
                </table>
            </b-row>

            <validation-observer ref="userRules">
                <b-form>
                    <b-row class="mt-2">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="Name">Name</label>
                                <validation-provider name="Name" #default="{ errors }" rules="required">
                                    <b-form-input id="name" type="text" v-model="userValue.name"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Surname -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Surname" label-for="surname">
                                <validation-provider name="Surname" #default="{ errors }" rules="required">
                                    <b-form-input id="surname" type="text" v-model="userValue.surname"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Email -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Email" label-for="email">
                                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                                    <b-form-input id="email" type="text" :disabled="userId > 0" v-model="userValue.email"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Password -->
                        <b-col cols="12" sm="6" md="4" xl="3" v-if="userValue.id == null">
                            <b-form-group label="Password" label-for="password">
                                <validation-provider name="Password" #default="{ errors }" rules="required">
                                    <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3" v-else>
                            <b-form-group label="Password" label-for="password">
                                <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <!-- Field: Role -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="User Role" label-for="role">
                                <validation-provider name="role" #default="{ errors }" rules="required">
                                    <v-select v-model="userValue.role" :options="roleOptions" :reduce="(val) => val.name" label="name" :clearable="false" input-id="role" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Field: Status -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Status" label-for="status">
                                <validation-provider name="status" #default="{ errors }" rules="required">
                                    <v-select v-model="userValue.status" :options="statusOptions" :reduce="(val) => val.value" :clearable="false" input-id="status" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="12" md="8" xl="6" v-if="userValue.role != null && userValue.role != 'supermanager'">
                            <b-form-group label="Company Name" label-for="companyName">
                                <validation-provider name="Company Name" #default="{ errors }" :rules="userValue.role != null && userValue.role != 'supermanager' ? 'required' : ''">

                                <v-select
                                    v-model="userValue.companyId"
                                    :taggable="true"
                                    :create-option="(temp) => ({ id: 0, name: temp, who: 'companyName' })"
                                    :loading="dropdownLoading"
                                    @option:created="createNewDropdownValue"
                                    @option:selected="selectedDropdownValue"
                                    :options="companyList"
                                    label="name"
                                    :reduce="(val) => val.id"
                                    :clearable="true"
                                >
                                </v-select>
                                <small class="text-danger">{{ errors[0] }}</small>

                            </validation-provider>

                            </b-form-group>
                        </b-col>

                        <b-col
                            cols="12"
                            sm="12"
                            md="4"
                            xl="3"
                            class="mt-2"
                            v-if="userValue.role != null && userValue.role != 'supermanager' && userValue.companyId != null && userValue.companyId != 0"
                        >
                            <b-img-lazy thumbnail fluid v-bind="mainProps" style="width: 100%" ref="refPreviewEl" :src="baseURL + imageUrl" />

                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" @click="$refs.refInputEl.click()" block variant="primary"> Company Logo </b-button>
                        </b-col>

                        <b-col
                            cols="12"
                            sm="12"
                            md="4"
                            xl="3"
                            class="mt-2"
                            v-if="userValue.role != null && userValue.role != 'supermanager' && userValue.companyId != null && userValue.companyId != 0"
                        >
                            <b-form-group label="Company Dashboard URL" label-for="surname">
                                <b-form-input id="dashboard" type="text" v-model="userValue.companyUrl"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <!-- <b-form-file ref="refInputEl" class="d-none" accept="image/*" @input="imageSelected" v-model="tempImage" drop-placeholder="Drop file here..." /> -->

                        <input ref="refInputEl" type="file" class="d-none" accept="image/*" @input="imageSelected" />

                        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                            <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                        </b-col>

                        <b-col cols="12" class="mt-5">
                            <b-button v-if="userId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save
                            </b-button>
                            <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save Changes
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <image-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
        </b-card>
    </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";

import store from "@/store";
import userStoreModule from "../userStoreModule";
import ImageCropper from "@core/components/image-cropper/ImageCropper.vue";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,

        vSelect,
        Cleave,
        ToastificationContent,
        ImageCropper,
        ValidationProvider,
        ValidationObserver,
    },

    directives: {
        Ripple,
        "b-modal": VBModal,
    },

    setup() {
        const USER_APP_STORE_MODULE_NAME = "user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var userValue = {
            name: null,
            surname: null,
            email: null,
            role: null,
            password: null,
            status: null,
            companyUrl: "https://www.rheliteservices.com/",

            companyLogo: null,
        };

        const statusOptions = [
            { label: "Active", value: "active" },
            { label: "Passive", value: "passive" },
        ];

        return {
            userValue: userValue,
            imageUrl: "company.png",
            baseURL: store.state.app.baseURL,
            selectedImage: null,
            croppedImage: null,
            userData: JSON.parse(localStorage.getItem("userData")),
            required,
            alphaNum,
            email,
            dropdownLoading: false,
            mainProps: {
                blank: true,
                blankColor: "#777",
                width: 75,
                height: 75,
                class: "m1",
            },
            companyList: [],
            percentCompleted: 0,
            roleOptions: [
                {
                    id: 1,
                    name: "supermanager",
                    description: "Super Manager. Have access and full permission to every thing.",
                    permissions: [],
                },
                {
                    id: 2,
                    name: "user",
                    description: "User",
                    permissions: [],
                },
            ],
            statusOptions,
            userId: null,
            formShow: false,
            permissions: [],

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },
        };
    },

    created() {
        this.getCompany();
        this.getUserById();
    },

    watch: {
        "userValue.companyId": {
            handler: function(val, before) {
                var tempCompany = null;
                this.companyList.forEach((element) => {
                    if (element.id == val) {
                        tempCompany = element;
                    }
                });

                if (tempCompany != null) {
                    if (tempCompany.logoLink == null) {
                        this.imageUrl = "company.png";
                    } else {
                        this.imageUrl = tempCompany.logoLink;
                    }
                    this.userValue.companyUrl = tempCompany.url;
                } else {
                    this.imageUrl = "company.png";
                    this.userValue.companyUrl = "https://www.rheliteservices.com/";
                }
            },
        },
    },

    methods: {
        getCompany() {
            store
                .dispatch("user/getCompany", [])
                .then((res) => {
                    this.companyList = res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getUserById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.userId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("user/fetchUser", { id: router.currentRoute.params.id })
                    .then((response) => {
                        this.userValue = response.data;
                        this.userValue.companyLogo = null;
                        if (this.userValue.company != undefined && this.userValue.company != "") {
                            this.imageUrl = this.userValue.company.logoLink;
                        }

                        this.formShow = false;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.userValue = undefined;
                        }
                    });
            } else {
                this.formShow = false;
            }
        },

        createNewDropdownValue(val) {
            this.dropdownLoading = true;

            switch (val.who) {
                case "companyName":
                    if (this.companyList.find((x) => x.name.toUpperCase() == val.name.toUpperCase()) == undefined) {
                        store.dispatch("user/saveCompany", { name: val.name.toUpperCase().trim() }).then((response) => {
                            this.companyList.unshift(response.data);
                            this.userValue.companyId = response.data.id;

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ Company Add Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });

                            this.dropdownLoading = false;
                        });
                    } else {
                        this.dropdownLoading = false;
                    }

                    break;

                default:
                    break;
            }
        },

        imageSelected(event) {
            const file = event.target.files ? event.target.files[0] : null;

            if (file != null) {
                this.getBase64(file).then((data) => {
                    this.selectedImage = data;
                });
            }
        },
        imageCropped(croppedImage) {
            if (croppedImage != null) {
                this.$emit("update:formShow", true);
                let formData = new FormData();
                formData.append("file", croppedImage);
                const config = {
                    onUploadProgress: function(progressEvent) {
                        this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }.bind(this),
                };
                axiosIns
                    .post("cropCompanyLogo", formData, config)
                    .then((response) => {
                        this.imageUrl = response.data;

                        this.tempImage = null;
                        this.selectedImage = null;
                        this.userValue.companyLogo = response.data;

                        this.$emit("update:formShow", false);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Upload Successful",
                                text: "✔️ Image Cropped Successfully",
                                icon: "ThumbsUpIcon",
                                variant: "success",
                            },
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit("update:formShow", false);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Upload Not Successful",
                                text: "⛔ Image Upload Not Successful",
                                icon: "ThumbsDownIcon",
                                variant: "danger",
                            },
                        });
                    });
            } else {
                this.tempImage = null;
                this.selectedImage = null;
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        selectedDropdownValue(val) {
            switch (val.who) {
                case "companyName":
                    this.userValue.companyId = this.companyList.filter((x) => x.name.toUpperCase() == val.name.toUpperCase())[0];
                    if (this.userValue.companyId != undefined && this.userValue.companyId != 0) {
                        this.userValue.companyId = this.companyList.filter((x) => x.name.toUpperCase() == val.name.toUpperCase())[0].id;
                    }

                    break;

                default:
                    break;
            }
        },

        formSubmitted() {
            this.formShow = true;

            this.$refs.userRules.validate().then((success) => {
                if (success) {
                    if (router.currentRoute.params.id) {
                        store.dispatch("user/updateUser", this.userValue).then((response) => {
                            if (response.status == 200) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Successful",
                                        text: "✔️ User Update Successful",
                                        icon: "ThumbsUpIcon",
                                        variant: "success",
                                    },
                                });

                                setTimeout(function() {
                                    this.formShow = false;

                                    // window.location.reload(true);
                                    router.push({ name: "user-list" });
                                }, 100);
                            }
                        });
                    } else {
                        store
                            .dispatch("user/userCheck", { email: this.userValue.email })
                            .then((response) => {
                                if (response.data == "no") {
                                    store.dispatch("user/addUser", this.userValue).then((response) => {
                                        if (response.status == 200) {
                                            this.$toast({
                                                component: ToastificationContent,
                                                props: {
                                                    title: "Successful",
                                                    text: "✔️ User Add Successful",
                                                    icon: "ThumbsUpIcon",
                                                    variant: "success",
                                                },
                                            });

                                            setTimeout(function() {
                                                this.formShow = false;
                                                router.push({ name: "user-list" });
                                            }, 1000);
                                        }
                                    });
                                } else {
                                    this.formShow = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "This email exists",
                                            text: "Please enter another email address.",
                                            icon: "RefreshCcwIcon",
                                            variant: "warning",
                                        },
                                    });
                                }
                            })
                            .catch((error) => {});
                    }
                } else {
                    this.formShow = false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
